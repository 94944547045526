import React from "react"

import Header from "../Header"

// Global styles and component-specific styles.
import "../../styles/global.css"
import styles from "./main.module.css"

const Layout = ({ children }) => (
  <div>
    <Header />
    <main className={styles.main}>{children}</main>
  </div>
)

export default Layout
