import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Form from "../components/Form"
import View from "../components/View"
import Status from "../components/Status"

const Index = () => (
  <Layout>
    <Status />
    <View>
      I should put something here...
    </View>
  </Layout>
)

export default Index
