import React from "react"
import { Link } from "gatsby"
import { isLoggedIn, isAuthenticated } from "../../utils/auth.js"
import styles from "./header.module.css"

export default () => {
  let headers;
  if (isLoggedIn()) {
  // if (isAuthenticated()) {
    headers = (
      <nav role="main" className={styles[`header__nav`]}>
        <Link to="/app/stories" className={styles[`header__link`]}>
          Stories
        </Link>
        <Link to="/app/thoughts" className={styles[`header__link`]}>
          Thoughts
        </Link>
        <Link to="/app/resume" className={styles[`header__link`]}>
          Resume
        </Link>
      </nav>
    );
  } else {
    headers = (
      <nav role="main" className={styles[`header__nav`]}>
      </nav>
    );
  }

  return (
    <header className={styles.header}>
      <div className={styles[`header__wrap`]}>
        <h1 className={styles[`header__heading`]}>
          <Link
            to="/"
            className={`${styles[`header__link`]} ${
              styles[`header__link--home`]
            }`}
          >
            A Website
          </Link>
        </h1>
        {headers}
      </div>
    </header>
  );
}
